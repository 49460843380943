import axios from "axios";
import ajax from "../../ajax";
import {UrlCreator} from "../../../components/select_data";

let cacheCountriesList = null;

export const getCountriesList = () => {
    return axios.get(UrlCreator.countries(), ajax.getBaseConfig())
}

export const getCachedCountriesList = () => {
    if (cacheCountriesList === null) {
        cacheCountriesList = getCountriesList()
    }
    return cacheCountriesList
}
