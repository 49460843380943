import React from "react";

import {allowAdminOptions} from "../../../../components/admins_list";

export default function ListGroupByParams(user) {
    const keyDate = 'date';
    const keyDateHour = 'date_hour';
    const keyManager = 'manager';
    const keyAccount = 'account';
    const keyPubAccount = 'pub_account';
    const keyZone = 'zone';
    const keyCountry = 'country';
    const keyCampaign = 'campaign';
    const keyPlacement = 'placement';
    const keyCampaignPlacement = 'campaign_placement';

    const items = React.useMemo(() => [
        {
            title: "Date",
            key: keyDate,
        },
        {
            title: "Hour",
            key: keyDateHour,
        },
        {
            title: "Manager",
            key: keyManager,
            visible: allowAdminOptions(user.id),
        },
        {
            title: "Account",
            key: keyAccount,
        },
        {
            title: "Pub Account",
            key: keyPubAccount,
        },
        {
            title: "Zone",
            key: keyZone,
        },
        {
            title: "Country",
            key: keyCountry,
        },
        {
            title: "Campaign",
            key: keyCampaign,
        },
        {
            title: "Placement",
            key: keyPlacement,
        },
        {
            title: "Campaign + Placement",
            key: keyCampaignPlacement,
        },
    ], []);

    return [{keyDate, keyDateHour, keyManager, keyAccount, keyPubAccount, keyCampaign, keyZone, keyCountry, keyPlacement, keyCampaignPlacement, items}];
}
