import React from "react";

import {allowAdminOptions} from "../../../../components/admins_list";

export default function ListGroupByParams(user) {
    const keyDate = 'date';
    const keyManager = 'manager';
    const keyAccount = 'account';
    const keyPubAccount = 'pub_account';
    const keyCampaign = 'campaign';
    const keyZone = 'zone';
    const keyZoneTb = 'zone_tb';
    const keyCountry = 'country';
    const keyType = 'type';

    const items = React.useMemo(() => [
        {
            title: "Date",
            key: keyDate,
        },
        {
            title: "Manager",
            key: keyManager,
            visible: allowAdminOptions(user.id),
        },
        {
            title: "Account",
            key: keyAccount,
        },
        {
            title: "Pub Account",
            key: keyPubAccount,
        },
        {
            title: "Campaign",
            key: keyCampaign,
        },
        {
            title: "Zone",
            key: keyZone,
        },
        // {
        //     title: "TB zone",
        //     key: keyZoneTb,
        // },
        {
            title: "Country",
            key: keyCountry,
        },
        {
            title: "Type",
            key: keyType,
        },
    ], []);

    return {
        keyDate, keyManager, keyAccount, keyPubAccount, keyCampaign,
        keyZone, keyZoneTb, keyCountry, keyType,
        items
    };
}
