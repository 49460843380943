import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import axios from "axios";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import Table from "../../components/table/Table";
import TableParams from "./ZoneListTableParams";
import ajax from "../../../data/ajax";
import {SelectDataFormatter, UrlCreator} from "../../../components/select_data";
import {consoleLogResponse} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import ButtonFilter from "../../components/ButtonFilter";
import SelectMulti from "../../components/select/SelectMulti";
import {useSelect} from "../../../components/input-hook";
import Filter from "../../../components/filter";
import TableColumns from "../../components/table/TableColumns";
import {getCachedManagersList} from "../../../data/collections/managers/list";
import {ObjectRemoveEmptyForUrl} from "../../../components/helpers";
import BackendRequest from "../../../data/BackendRequest";

const ZoneList = ({user}) => {
    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const localStorageTableKey = 'table_zone_list_rollerads';
    const [tableParams] = TableParams();

    const [tableGroupHiddenColumns] = useState([]);
    const [tableUserHiddenColumns, setUserTableHiddenColumns] = useState();
    const [tableHiddenColumns, setTableHiddenColumns] = useState([]);
    useEffect(() => {
        setTableHiddenColumns(tableGroupHiddenColumns.concat(tableUserHiddenColumns))
    }, [tableGroupHiddenColumns, tableUserHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const [filterButton, setFilterButton] = useState(0);

    const inputFilterAccount = useSelect([]);
    const inputFilterPubAccount = useSelect([]);
    const inputFilterSite = useSelect([]);
    const inputFilterDirection = useSelect([]);
    const inputFilterAvailable = useSelect([]);
    const inputFilterManager = useSelect([]);

    const [dataRequestParams, setDataRequestParams] = useState({});
    const [{data, isLoading}, setUrlParams] = BackendRequest('ex-stat/zone-rollerads');

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = ajax.getBaseConfig();
            const promises = [
                axios.get(UrlCreator.accounts({'type': 'advertiser'}), requestConfig),
                axios.get(UrlCreator.accounts({'type': 'publisher', 'type_id': 9}), requestConfig),
                axios.get(UrlCreator.sites(), ajax.getBaseConfig()),
                getCachedManagersList(),
            ];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);

                let filters = [
                    {
                        key: 'accounts',
                        data: SelectDataFormatter.accounts(results[0].data),
                        input: inputFilterAccount,
                    },
                    {
                        key: 'pub_accounts',
                        data: SelectDataFormatter.accounts(results[1].data),
                        input: inputFilterPubAccount,
                    },
                    {
                        key: 'origin_site_id',
                        data: SelectDataFormatter.sites(results[2].data),
                        input: inputFilterSite,
                    },
                    {
                        key: 'direction',
                        data: SelectDataFormatter.directions(),
                        input: inputFilterDirection,
                    },
                    {
                        key: 'is_available',
                        data: SelectDataFormatter.bool(),
                        input: inputFilterAvailable,
                    },
                    {
                        key: 'managers',
                        data: SelectDataFormatter.managers(results[3].data),
                        input: inputFilterManager,
                    },
                ];

                let params = Filter.collectParamsForFilter(location, filters)

                setIsLoadedFilterData(true);
            });
        };

        if (!isLoadedFilterData) {
            fetchData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            setDataRequestParams({
                accounts: inputFilterAccount.value,
                pub_accounts: inputFilterPubAccount.value,
                origin_site_id: inputFilterSite.value,
                direction: inputFilterDirection.value,
                is_available: inputFilterAvailable.value,
                managers: inputFilterManager.value,
            })
        }
    }, [
        isLoadedFilterData,
        inputFilterAccount.value,
        inputFilterPubAccount.value,
        inputFilterSite.value,
        inputFilterDirection.value,
        inputFilterAvailable.value,
        inputFilterManager.value,
        filterButton
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(dataRequestParams).length > 0) {
            navigate(locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(dataRequestParams))).toString());
            setUrlParams(dataRequestParams);
        }
    }, [dataRequestParams]);

    return (
        <Container title={"Rollerads Zones List"}>
            <LoadingWrapper isLoaded={isLoadedFilterData}>
                <Card>
                    <div className="row">
                        <div className="col-md-4">
                            <b>Select pub account</b>
                            <SelectMulti {...inputFilterPubAccount.bind}/>
                        </div>
                        <div className="col-md-4">
                            <b>Select site</b>
                            <SelectMulti {...inputFilterSite.bind}/>
                        </div>
                        <div className="col-md-4">
                            <b>Select direction</b>
                            <SelectMulti {...inputFilterDirection.bind}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <b>Select account</b>
                            <SelectMulti {...inputFilterAccount.bind}/>
                        </div>
                        <div className="col-md-4">
                            <b>Select manager</b>
                            <SelectMulti {...inputFilterManager.bind}/>
                        </div>
                        <div className="col-md-4">
                            <b>Select available</b>
                            <SelectMulti {...inputFilterAvailable.bind}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <ButtonFilter setFilterButton={v => setFilterButton(v)} />
                        </div>
                        <div className="col-md-6">&nbsp;</div>
                        <div className="col-md-3 text-right">
                            <TableColumns
                                storageKey={localStorageTableKey}
                                columns={tableParams.columns}
                                setUserTableHiddenColumns={v => setUserTableHiddenColumns(v)}
                            />
                        </div>
                    </div>
                </Card>
            </LoadingWrapper>

            <LoadingWrapper isLoaded={isLoadedFilterData && !isLoading}>
                <Card>
                    <Table
                        data={data}
                        {...tableParams}
                        hiddenColumns={tableHiddenColumns}
                    />
                </Card>
            </LoadingWrapper>
        </Container>
    );
}

export default ZoneList;
