import axios from "axios";
import ajax from "../../ajax";
import {UrlCreator} from "../../../components/select_data";

let cacheManagersList = null;

export const getManagersList = () => {
    return axios.get(UrlCreator.managers(), ajax.getBaseConfig())
}

export const getCachedManagersList = () => {
    if (cacheManagersList === null) {
        cacheManagersList = getManagersList()
    }
    return cacheManagersList
}
