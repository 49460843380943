import React, {useState, useEffect} from 'react';
import moment from 'moment';
import axios from "axios";

import Container from "../components/Container";
import Card from "../components/Сard";
import FilterGroupBy from "../components/FilterGroupBy";
import Table from "../components/table/Table";
import DateRangePicker from "../components/DateRangePicker";
import DataModel from "../../data/DataLss";
import LssTableParams from "./Lss/LssTableParams";
import LssFilterGroupByParams from "./Lss/LssFilterGroupByParams";
import SelectCountry2 from "../components/select/SelectCountry2";
import ajax from "../../data/ajax";
import {SelectDataFormatter} from "../../components/select_data";
import {consoleLogResponse} from "../../components/development";
import {getCachedCountriesList} from "../../data/collections/country/list";

/**
 * @deprecated
 * @param user
 * @returns {Element}
 * @constructor
 */
const Lss = ({user}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    // const [data, setData] = useState([]);

    const isAdmin = true; // user.role === 'admin';

    const [{
        keyDate: tableKeyDate,
        keyCountry: tableKeyCountry,
        keyUserEmail: keyUserEmail,
        keyProfit: keyProfit,
        columns: tableColumns,
        initialState: tableInitialState,
    }] = LssTableParams();

    const [{
        keyDate: filterGroupByKeyDate,
        keyCountry: filterGroupByKeyCountry,
        keyZone: filterGroupByKeyZone,
        items: filterGroupByItems,
    }] = LssFilterGroupByParams(isAdmin);

    const [filterGroupBy, setFilterGroupBy] = useState(filterGroupByKeyDate);
    const [tableHiddenColumns, setTableHiddenColumns] = useState([tableKeyDate]);

    const [calendarDates, setCalendarDates] = useState({
        start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });
    const [filterCountry, setFilterCountry] = useState('');
    const [filterUser, setFilterUser] = useState('');
    const [filterButton, setFilterButton] = useState(0);

    const [listCountry, setListCountry] = useState([]);
    const [listUser, setListUser] = useState([]);

    const [{data}, setUrlParams] = DataModel();

    const defaultHiddenColumns = isAdmin ? [] : [keyUserEmail, keyProfit]

    useEffect(() => {
        // setIsLoaded(true);
        setUrlParams({
            group: filterGroupBy,
            date_from: calendarDates.start,
            date_to: calendarDates.end,
        });

        const fetchData = async () => {
            const requestConfig = ajax.getBaseConfig();

            const promises = [
                getCachedCountriesList(),
            ];
            if (isAdmin) {
                promises.push(axios.get(window.trackerBaseUrl + "user-list", requestConfig))
            }

            Promise.all(promises).then(function (results) {
                consoleLogResponse(results)

                const countries = SelectDataFormatter.countries(results[0].data);
                setListCountry(countries);

                if (isAdmin) {
                    const users = results[1].data.map(i => {
                        return {value: i.lss_zone, label: i.email}
                    });
                    setListUser(users);
                }

                setIsLoaded(true);
            });
        };

        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (filterGroupBy === filterGroupByKeyCountry) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate]))
        } else if (filterGroupBy === filterGroupByKeyDate) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyCountry]))
        } else if (filterGroupBy === filterGroupByKeyZone) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate, tableKeyCountry]))
        } else {
            setTableHiddenColumns(defaultHiddenColumns)
        }

        let params = {
            group: filterGroupBy,
            date_from: calendarDates.start,
            date_to: calendarDates.end,
            country: filterCountry,
        }
        if (isAdmin) {
            params.lss_zone = filterUser
        }

        setUrlParams(params)
    }, [filterGroupBy, calendarDates, filterCountry, filterUser, filterButton]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Statistics"} isLoaded={isLoaded} error={error}>
            <Card>
                <div className="row">
                    <div className="col-md-4 text-center">
                        <DateRangePicker dates={calendarDates} updateDates={v => setCalendarDates(v)}/>
                    </div>
                    <div className="col-md-4">
                        <SelectCountry2 value={filterCountry} updateValue={v => setFilterCountry(v)}
                                        options={listCountry} defaultLabel="Select country"/>
                        {/*
                        <select className="form-control mb-3">
                            <option selected>Country</option>
                            <option>One</option>
                            <option>Two</option>
                            <option>Three</option>
                        </select>
*/}
                    </div>
                    <div className="col-md-4">
                        {
                            isAdmin
                            && <SelectCountry2 value={filterUser} updateValue={v => setFilterUser(v)}
                                               options={listUser} defaultLabel="Select user"/>
                        }
                    </div>

                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={e => {
                            const t = Date.now();
                            setFilterButton(t)
                        }}>Show Results
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <FilterGroupBy
                            items={filterGroupByItems}
                            value={filterGroupBy}
                            updateValue={v => setFilterGroupBy(v)}
                        />
                    </div>
                </div>
            </Card>
            <Card>
                <div className="card-header">Time zone: UTC-5 (EST)</div>
                <Table
                    columns={tableColumns}
                    data={data}
                    initialState={tableInitialState}
                    hiddenColumns={tableHiddenColumns}
                />
            </Card>
        </Container>
    );
}

export default Lss;
